<script>
	import store from '../../store';
	const pageName = 'splash';
	const nextPage = 'terms-conditions';
	export default {
		beforeRouteEnter(to, from, next) {
			// if the token is new
			if(to.params.userId !== store.state.userId) {
				// reset storage
				store.dispatch('resetStore');
			}
			// if token is the same &
			// the page viewed in this session
			else if(store.getters.pageViewed(pageName)) {
				// redirect
				return next({name: nextPage});
			}
			// load page
			next();
		},
		beforeCreate() {
			// save user data
			this.$store.dispatch('storeUserData', {
				token: this.$route.params.token,
				id: this.$route.params.userId
			});

			setTimeout(() => {
				// save page view
				this.$store.dispatch('pageViewed', pageName);
				// redirect to next page
				this.$router.replace({name: nextPage});
			}, 3000);
		},
	}
</script>
<template>
	<div>
		<div class="overlay">
			<div class="brand">
				<img src="../../assets/images/fradi-logo.svg"/>
				<div class="title">{{$t('app-name')}}</div>
			</div>
			<div class="brand2">
				<img src="../../assets/images/groupama.svg"/>
			</div>
		</div>
	</div>
</template>
<style scoped>
	#splash {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		background: var(--theme-color-3) url('../../assets/images/splash.jpg') no-repeat;
		background-size: cover;
		z-index: 10000;
	}
	.overlay {
		background: linear-gradient(#000000CC, #00000000, #000000CC);
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		text-align: center;
	}
	.brand {
		margin: auto;
		color: white;
		font-size: 38px;
		font-weight: bold;
		font-family: Helvetica, sans-serif;
	}
	.brand2 {
		padding-bottom: 9vh;
		width: 70%;
		max-width: 500px;
		margin: auto;
	}
</style>